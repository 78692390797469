<template>
  <div class="flex content-start justify-center w-full">
    <h4 class="mx-0 text-center" :class="[props.textColor, props.classes]">
      {{ props.headline }}
    </h4>
    <div
      class="w-full ml-4 leading-6 border-t-0 border-dashed border-b-1 border-x-0"
      :class="props.borderColor"
    />
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(defineProps<Props>(), {
  headline: 'headline',
  textColor: 'text-white',
  borderColor: 'border-white',
  classes: '',
});
interface Props {
  headline: string;
  classes?: string;
  textColor?: string;
  borderColor?: string;
}
</script>
